@import "variables";

@import "~startbootstrap-sb-admin-2/scss/sb-admin-2";

// @import "https://assets.echofin.com/icons/fa/css/all.min.css";


@import "~@swimlane/ngx-datatable/index.css";
@import "./node_modules/@swimlane/ngx-datatable/themes/bootstrap.scss";
@import "~@swimlane/ngx-datatable/assets/icons.css";

@import "custom";
