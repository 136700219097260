.datatable-row-left {
  background: white;
}

.sidebar {
  .sidebar-heading {
    text-transform: none !important;
    padding: 0.5rem 1.5rem;
    font-size: 0.9rem;
    font-weight: normal;
  }
  .sidebar-brand {
    .sidebar-brand-text {
      text-transform: none;
      font-size: 1.5rem;
    }
    .sidebar-brand-icon {
      color: $orange;
    }
  }
  .nav-item {
    .nav-link {
      padding: 0.5rem 1.5rem;
    }
  }
}

.ngx-datatable.bootstrap {
  .datatable-header .datatable-header-cell .datatable-header-cell-label {
    line-height: 24px;
    font-weight: bold;
  }
  .datatable-body .datatable-body-row.datatable-row-even {
    background-color: inherit;
  }
  .datatable-footer {
    background: #f8f9fc;
    color: #858796;
    margin-top: -1px;
    border-top: solid 1px #e3e6f0;

    .datatable-pager a {
      color: #a0a1ae;
    }

    ul li:not(.disabled).active a {
      color: #fff;
    }
  }
  datatable-body-cell {
    .btn-link {
      font-size: 0.8rem;
      padding-top: 0;
      padding-bottom: 0;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

button,
.btn {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.card {
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $dark;
    color: $white;
  }
  &.card-table {
    overflow: hidden;
  }
  .card-filters {
    background-color: $light;
    border-bottom: solid 1px darken($light, 5%);
  }
  .card-actions {
    display: flex;
    .card-action {
      border: none;
      background: none;
      color: lighten($dark, 40%);
      font-size: 0.8rem;
      width: 25px;
      position: relative;
      margin-left: 0.2rem;
      &:hover {
        color: $white;
      }
      &.card-action-indicator {
        &::after {
          content: "";
          width: 10px;
          height: 10px;
          background-color: lighten($primary, 10%);
          position: absolute;
          border-radius: 5px;
          border: solid 2px $dark;
          right: 0;
          top: 0;
        }
      }
    }
  }
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  h1 {
    margin-bottom: 0;
    color: $gray-700;
    font-size: 1.8rem;
    font-weight: normal;
  }
  .btn-refresh {
    background: none;
    border: none;
    color: $gray-600;
    &:hover {
      color: $gray-700;
    }
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
}

.page-toolbar {
  @extend .btn-toolbar;
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .mb-3;
  button {
    @extend .btn;
    @extend .btn-sm;
    @extend .btn-secondary;
    span {
      margin-right: 0.2rem;
    }
  }
}

#wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  // #content-wrapper {
  //   height: 100%;
  #content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;
    background-color: $light;
    .navbar {
      flex-shrink: 0;
    }
    .container-fluid {
      flex-grow: 1;
      overflow-y: auto;
      // padding-top: 1.5rem;
      // padding-bottom: 1.5rem;
    }
  }
}
// }

.datatable-body-row:nth-of-type(odd) {
  background-color: #fafafa;
}

.ngx-datatable.bootstrap:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
    background: #f6f6f6;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

*{
  outline: none !important;
}